<template>
  <div class="min-h-screen lg:h-screen flex flex-col lg:flex-row items-center justify-center">
    <div class="w-7/12 h-screen shadow-2xl hidden lg:flex aic jcc">
      <img
        src="/img/login-4.webp"
        alt="login"
        class="w-full h-screen object-cover mirror"
      >
    </div>
    <div class="flex flex-col items-center justify-start w-full lg:w-5/12 h-screen overflow-y-scroll p-7 lg:p-12">
      <div class="flex aic jcs mb-16 w-full">
        <span class="w-5 h-12 bg-brown-lightest shadow-xl"></span>
        <router-link
          to="/"
          class="flex flex-col ais jcc ml-5"
        >
          <small class="fs-10 text-gray-600 tracking-widest uppercase">Brötchen Shop</small>
          <h1 class="roboto-bold text-xl md:text-2xl">{{$t("reset password")}}</h1>
        </router-link>
        <router-link
          to="/"
          class="ml-auto"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-3 w-3 md:w-5 md:h-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path d="M12 11.293l10.293-10.293.707.707-10.293 10.293 10.293 10.293-.707.707-10.293-10.293-10.293 10.293-.707-.707 10.293-10.293-10.293-10.293.707-.707 10.293 10.293z" />
          </svg>
        </router-link>
      </div>
      <form
        @submit.prevent="forgotpass"
        class="my-auto w-full max-w-md"
      >
        <div class="flex flex-col auth-area">
          <div class="rounded-4xl text-left transform transition-all">
            <div>

              <div class="flex flex-col space-y-3">
                <!-- <span class="text-sm text-gray-900 tracking-wider whitespace-nowrap">{{$t("Please enter your email address")}}</span> -->
                <div class="mb-3">
                  <input
                    type="email"
                    name="email"
                    id="email"
                    v-model="email"
                    required
                    class="block w-full px-3 py-6 bg-transparent border-b border-gray-300 new-input dmSans-bold"
                    aria-label="Email"
                    placeholder="Email"
                  />
                </div>
              </div>
            </div>
            <div class="flex flex-col md:flex-row lg:flex-col xl:flex-row space-y-8 md:space-y-0 lg:space-y-8 xl:space-y-0 aic jce mb-16 lg:mb-32 mt-12 lg:mt-24">
              <!-- <div class="flex flex-col ais jcb w-full">
                <span class="text-sm tracking-wider">{{$t("Don't have an account?")}}</span>
                <router-link
                  to="/register"
                  class="a-black text-sm cursor-pointer b text-left"
                >{{$t("Register here!")}}</router-link>
              </div> -->
              <button
                type="submit"
                class="inline-flex nunitoSans-bold justify-center w-full md:max-w-max lg:max-w-2xl xl:max-w-max border border-transparent relative px-16 py-3 btn-brown-light-rev"
              >
                {{$t("Reset")}}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      email: null,
      showConfirm: false,
      msg: "",
      status: "",
    };
  },
  computed: {
    restaurantInfo() {
      return this.$store.state.orderiomApiPackage.restaurant.restaurantInfo;
    },
    logo() {
      return process.env.VUE_APP_LOGO;
    },
    restaurantId() {
      return this.$store.state.subscription.restaurantId;
    },
  },
  methods: {
    forgotpass() {
      this.$store
        .dispatch("subscription/getUserOriginId", {
          email: this.email,
        })
        .then((res) => {
          if (res.type == "success") {
            this.$store
              .dispatch("auth/forgotPassword", {
                restaurantId: this.restaurantId,
                email: this.email,
              })
              .then((res) => {
                this.$store.commit("setshowNotif", {
                  show: true,
                  msg: res.msg,
                  type: res.type,
                });
                if (res.type == "success") {
                  this.$router.push("/dashboard");
                }
              });
          } else {
            this.$store.commit("setshowNotif", {
              show: true,
              msg: res.msg,
              type: res.type,
            });
          }
        });
    },
  },
};
</script>
